import * as ActionType from "./actionTypes";

const defaultState = {
  carousel: [],
  adv_list: ["创新经营，灵活营销","高效协同，智慧管理","线上线下，便捷省心","运营管理，明智选择"],
  swiper_list: [
    {
      name: "云商小铺(基础版)",
      image: require("../../static/images/image_case_pinjia.png")
    },{
      name: "云商小铺(基础版)",
      image: require("../../static/images/image_case_feige.png")
    },{
      name: "智慧考勤",
      image: require("../../static/images/image_case_kaoqin.png")
    },{
      name: "云商小铺(基础版)",
      image: require("../../static/images/image_case_luye.png")
    },{
      name: "云商小铺(基础版)",
      image: require("../../static/images/image_case_mixiguo.png")
    },{
      name: "校园宝",
      image: require("../../static/images/image_case_school.png")
    },{
      name: "智慧食堂",
      image: require("../../static/images/image_case_shitang.png")
    },{
      name: "云商小铺(基础版)",
      image: require("../../static/images/image_case_yuedinghui.png")
    },{
      name: "云商小铺(旅游版)",
      image: require("../../static/images/image_case_zancuo.png")
    },{
      name: "B2B供应链商城",
      image: require("../../static/images/image_case_beauty.png")
    }
  ],
  un_init_classify_list: [{    //未初始化数据，即无法直接使用的数据
    classify_id: 1,
    classify_name: "电商",
    classify_en_name: "E-COMMERCE",
    app_list: [{
      app_name: "云商小铺",
      app_intro: "B2C微信电商平台",
      app_desc: "小程序+公众号一体化微信商城",
      app_id: 1,
      path: "/Detail/1"
    },{
      app_name: "货叮当",
      app_intro: "B2B微信小程序批发商城",
      app_desc: "助力企业跨入移动社交电商时代",
      app_id: 2,
      path: "/Detail/2"
    }
    ]
  },{
    classify_id: 2,
    classify_name: "AI应用",
    classify_en_name: "APPLICATION OF AI",
    app_list: [{
      app_name: "校园宝",
      app_intro: "智慧校园综合服务平台",
      app_desc: "推动校园信息化技术快速发展",
      app_id: 3,
      path: "/Detail/3"
    },{
      app_name: "易拾堂",
      app_intro: "智慧食堂订餐管理系统",
      app_desc: "优化餐饮服务，加强食堂管理",
      app_id: 4,
      path: "/Detail/4"
    }/*,{
      app_name: "AI考勤",
      app_intro: "智慧考勤管理系统",
      app_desc: "为企业打造数字化新工作方式",
      app_id: 105
    }*/
    ]
  },{
    classify_id: 3,
    classify_name: "营销推广",
    classify_en_name: "MARKETING PROMOTION",
    app_list: [{
      app_name: "微单页",
      app_intro: "微信公众号H5推广单页",
      app_desc: "助力品牌和产品推广更高效、更及时",
      app_id: 51,
      path: "/Detail/51"
    },{
      app_name: "微画册",
      app_intro: "微信公众号推广画册",
      app_desc: "为品牌展示提供便捷、直观、炫酷的新方式",
      app_id: 52,
      path: "/Detail/52"
    },{
      app_name: "微名片",
      app_intro: "微信电子名片",
      app_desc: "助力企业推广品牌文化和企业信息",
      app_id: 53,
      path: "/Detail/53"
    },{
      app_name: "微资讯",
      app_intro: "微信公众号公示和传达企业资讯",
      app_desc: "助力企业推广品牌文化，开辟了便捷的窗口",
      app_id: 54,
      path: "/Detail/54"
    }
    ]
  }
  ],
  init_classify_list: [],  //对数组进行初始化后，放在此数组
  reasonList: [{
    title: "崛鼎出品、品质领先",
    icon: require("../../static/images/image_icon1.png"),
    describe: "2013年成立迄今，崛鼎科技始终聚焦互联网云计算技术开发与运营服务，获多家企业的认可"
  },{
    title: "经营+推广，一体化解决方案",
    icon: require("../../static/images/image_icon2.png"),
    describe: "全面彰显品牌特性，与众不同，用户才能记住你；自建合伙人体系营销，收益都是我的，花出去的钱看得到效果"
  },{
    title: "高效运维，资金安全",
    icon: require("../../static/images/image_icon3.png"),
    describe: "数据存储，安全有效；线上线下一体化收银，银行负责资金托管，确保资金安全"
  },{
    title: "多元、高效的互联网工具",
    icon: require("../../static/images/image_icon4.png"),
    describe: "低成本，让中小型企业快速互联网化，最好的不一定是最贵的，专注电商是初心，帮助企业提升核心竞争力"
  }
  ],
  plan_menu: [{
    menu: "零售",
    menu_id: 0,
    bg_img: require("../../static/images/image_b2c_bg.png")
  },{
    menu: "批发",
    menu_id: 1,
    bg_img: require("../../static/images/image_b2b_bg.png")
  },{
    menu: "校园",
    menu_id: 2,
    bg_img: require("../../static/images/image_xiao_bg.png")
  },{
    menu: "食堂",
    menu_id: 3,
    bg_img: require("../../static/images/image_shi_bg.png")
  }
  ],
  plan_list: [{
    plan_id: 1,
    plan_title: "B2C商城解决方案",
    plan_desc: "云商小铺为中小商户或企业做电商（移动端）提供营销基础应用，并配套简易实用的营销服务体系辅助运营与销售；利用合伙人机制（传统代理形式），建立推广-吸粉-沉淀-交易-服务一体化的营销循环体系，实现线上线下场景的互联互通，助推企业快速实现营销、服务、效率转型升级。",
    plan_content_list: [{
      image: require("../../static/images/ic_b2c_1.png"),
      text: "商品管理"
    },{
      image: require("../../static/images/ic_b2c_2.png"),
      text: "订单管理"
    },{
      image: require("../../static/images/ic_b2c_3.png"),
      text: "客户管理"
    },{
      image: require("../../static/images/ic_b2c_4.png"),
      text: "优惠券"
    },{
      image: require("../../static/images/ic_b2c_5.png"),
      text: "限时秒杀"
    },{
      image: require("../../static/images/ic_b2c_6.png"),
      text: "满赠满减"
    },{
      image: require("../../static/images/ic_b2c_7.png"),
      text: "门店管理"
    },{
      image: require("../../static/images/ic_b2c_8.png"),
      text: "报表统计"
    },{
      image: require("../../static/images/ic_b2c_9.png"),
      text: "分销功能"
    },{
      image: require("../../static/images/ic_b2c_10.png"),
      text: "会员积分"
    },{
      image: require("../../static/images/ic_b2c_11.png"),
      text: "店铺装修"
    },{
      image: require("../../static/images/ic_b2c_12.png"),
      text: "客服系统"
    }
    ],
    app_logos: [require("../../static/images/image_b2c_logo1.png"),require("../../static/images/image_b2c_logo2.png"),require("../../static/images/image_b2c_logo3.png")],
    new_app_logos: []
  },{
    plan_id: 2,
    plan_title: "B2B批发商城解决方案",
    plan_desc: "货叮当电商平台是基于B2B运营模式的一套电商运营管理系统 。 拥有崛鼎自主研发的系统架构,结合崛鼎多年的开发经验与企业客户的需求反馈，而推出的一套适合新时代电商格局的B2B运维模式的电商系统。系统由商品管理、订单管理、买家管理、营销活动、资金管理、报表统计、报价管理等模块组成。",
    plan_content_list: [{
      image: require("../../static/images/ic_b2b_1.png"),
      text: "商品管理"
    },{
      image: require("../../static/images/ic_b2b_2.png"),
      text: "订单管理"
    },{
      image: require("../../static/images/ic_b2b_3.png"),
      text: "买家管理"
    },{
      image: require("../../static/images/ic_b2b_4.png"),
      text: "优惠满减"
    },{
      image: require("../../static/images/ic_b2b_5.png"),
      text: "报表统计"
    },{
      image: require("../../static/images/ic_b2b_6.png"),
      text: "报价管理"
    },{
      image: require("../../static/images/ic_b2c_5.png"),
      text: "特价秒杀"
    },{
      image: require("../../static/images/ic_b2b_8.png"),
      text: "进货包"
    },{
      image: require("../../static/images/ic_b2b_9.png"),
      text: "区域售卖"
    },{
      image: require("../../static/images/ic_b2b_10.png"),
      text: "资金管理"
    },{
      image: require("../../static/images/ic_b2c_10.png"),
      text: "会员积分"
    }
    ],
    app_logos: [require("../../static/images/image_b2b_logo1.png")],
    new_app_logos: []
  },{
    plan_id: 3,
    plan_title: "智慧校园解决方案",
    plan_desc: "校园宝是为学生、家长、学校之间提供了一个开放的、可扩展的、可持续服务的校园智慧综合服务平台。以家校互联为基础，以智慧管理为核心，以智能支付为支撑，以平安校园为保障，以和谐家校为纽带，以优势发展为导向，最终建成促进学生、家长、学校优势均衡发展的多功能数字化平台。",
    plan_content_list: [{
      image: require("../../static/images/ic_xiao_1.png"),
      text: "学员管理"
    },{
      image: require("../../static/images/ic_xiao_2.png"),
      text: "家长管理"
    },{
      image: require("../../static/images/ic_xiao_3.png"),
      text: "账单管理"
    },{
      image: require("../../static/images/ic_xiao_4.png"),
      text: "报表统计"
    },{
      image: require("../../static/images/ic_xiao_5.png"),
      text: "收费项"
    },{
      image: require("../../static/images/ic_xiao_6.png"),
      text: "进出记录"
    }
    ],
    app_logos: [require("../../static/images/image_xiao_logo1.png")],
    new_app_logos: []
  },{
    plan_id: 4,
    plan_title: "智慧食堂解决方案",
    plan_desc: "易拾堂迎合当下互联网时代潮流，基于现有食堂管理模式，创新打造便捷的食堂订餐&管理模式，可实现精准备餐/排餐、节约成本、杜绝浪费、加强就餐员工和食堂的互动、 提升就餐体验和食堂服务水平。同时具备强大的管理功能，不仅包括食材、菜品、菜单、报表统计、员工管理、餐补津贴充值扣款等基础食堂管理功能，还有多食堂总管控能力， 能满足不同规模的食堂管理需求。",
    plan_content_list: [{
      image: require("../../static/images/ic_shi_1.png"),
      text: "食堂管理"
    },{
      image: require("../../static/images/ic_shi_2.png"),
      text: "菜单管理"
    },{
      image: require("../../static/images/ic_shi_3.png"),
      text: "菜品管理"
    },{
      image: require("../../static/images/ic_shi_4.png"),
      text: "评价管理"
    },{
      image: require("../../static/images/ic_shi_5.png"),
      text: "线上订餐"
    },{
      image: require("../../static/images/ic_shi_6.png"),
      text: "充值扣款"
    },{
      image: require("../../static/images/ic_shi_7.png"),
      text: "投票"
    },{
      image: require("../../static/images/ic_shi_8.png"),
      text: "问卷调查"
    },{
      image: require("../../static/images/ic_shi_9.png"),
      text: "活动报名"
    },{
      image: require("../../static/images/ic_shi_10.png"),
      text: "健康资讯"
    }],
    app_logos: [],
    new_app_logos: [],
  }
  ],
  plan_index: 0,
  index_ul_list: [{
    title: "安全稳定",
    desc: "集崛鼎多年的技术沉淀，加上银行负责资金托管，全方位监控，为您的业务保驾护航。",
    image: require("../../static/images/ul_icon_1.png")
  },{
    title: "极致体验",
    desc: "人工智能、云计算、大数据、物联网等于现代制造业相结合，让你的用户无论身在何处，均能获得灵活流畅的极致体验。",
    image: require("../../static/images/ul_icon_2.png")
  },{
    title: "智慧高效",
    desc: "大数据和人工智能技术，精准会员营销等，崛鼎科技助您的企业全面飞跃发展。",
    image: require("../../static/images/ul_icon_3.png")
  }
  ],
  development_process: [{
    serial_number: "01",
    title: "需求梳理",
    desc: "协助企业梳理应用场景，给予咨询服务支持"
  },{
    serial_number: "02",
    title: "方案设计",
    desc: "量身定制可执行、可落地的解决方案，提供场景案例分享和答疑，输出原型设计"
  },{
    serial_number: "03",
    title: "UI设计",
    desc: "设计师根据产品特性和用户属性进行UI设计，提供最适合您的设计方案，提升产品体验"
  },{
    serial_number: "04",
    title: "程序开发",
    desc: "根据产品需求和原型，工程师进入开发阶段，规范的代码保障项目质量"
  },{
    serial_number: "05",
    title: "测试上线",
    desc: "通过严格的内部测试，及时发现并修复bug，完善产品，保证顺利部署上线"
  }
  ],
  logo_list: [{
    un_logo: require("../../static/images/image_logo_pinjia_grey.png"),
    logo: require("../../static/images/image_logo_pinjia_color.png")
  },{
    un_logo: require("../../static/images/image_logo_zancuo_grey.png"),
    logo: require("../../static/images/image_logo_zancuo_color.png")
  },{
    un_logo: require("../../static/images/image_logo_yuedinghui_grey.png"),
    logo: require("../../static/images/image_logo_yuedinghui_grey-1.png")
  },{
    un_logo: require("../../static/images/image_logo_qinqin_grey.png"),
    logo: require("../../static/images/image_logo_qinqin_color.png")
  },{
    un_logo: require("../../static/images/image_logo_pufa_grey.png"),
    logo: require("../../static/images/image_logo_pufa_color.png")
  },{
    un_logo: require("../../static/images/image_logo_mixiguo_grey.png"),
    logo: require("../../static/images/image_logo_mixiguo_color.png")
  },{
    un_logo: require("../../static/images/image_logo_luye_grey.png"),
    logo: require("../../static/images/image_logo_luye_color.png")
  },{
    un_logo: require("../../static/images/image_logo_lian_grey.png"),
    logo: require("../../static/images/image_logo_lian_color.png")
  },{
    un_logo: require("../../static/images/image_logo_gree_grey.png"),
    logo: require("../../static/images/image_logo_gree_color.png")
  },{
    un_logo: require("../../static/images/image_logo_feige_grey.png"),
    logo: require("../../static/images/image_logo_feige_color.png")
  },{
    un_logo: require("../../static/images/image_logo_dian_grey.png"),
    logo: require("../../static/images/image_logo_dian_color.png")
  },{
    un_logo: require("../../static/images/image_logo_caoruji_grey.png"),
    logo: require("../../static/images/image_logo_caoruji_color.png")
  }
  ]
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.INIT_CLASSIFY_LIST:
      newState[ActionType.INIT_CLASSIFY_LIST] = action.value;
      break;
    case ActionType.PLAN_INDEX:
      newState[ActionType.PLAN_INDEX] = action.value;
      break;
    case ActionType.PLAN_CAROUSEL:
      action.value.forEach(item =>{
        let time = (new Date()).getTime();
        newState.carousel.push({
          link_url: item.LinkUrl,
          img_url: item.imgUrl + `?t=${time}`
        })
      })
      break;
    case ActionType.PLAN_DATA:
      let res = action.value;
      res.plan_list.forEach((item) =>{
        let len = item.app_logos.length;
        let num = Math.ceil(len / 5);
        item.new_app_logos = [];
        for (let i = 0; i < num; i++){
          let start = i * 5;
          let end = (i * 5) + 5;
          item.new_app_logos.push(item.app_logos.slice(start, end));
        }
      });
      newState.plan_list = res.plan_list;
      res.plan_menu.forEach((item,index) => { item.menu_id = index });
      newState.plan_menu = res.plan_menu;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}