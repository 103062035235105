import * as ActionType from "./actionTypes";

const defaultState = {
  reqData: {

  },
  product_name: "",
  product_title: "免费体验",
  product_desc: "崛鼎云正在为众多企业提供可靠、便捷、高效、贴心的产品服务",
  banner: require("../../static/images/merchant_bg.jpg"),
  merchant_app_list: [],
  ProvinceJson: [],
  CityJson: [],
  RegionJson: [],
  aliIndustryList_Json: [],
  aliSubIndustryList_Json: [],
  industryList_Json: [],
  subIndustryList_Json: [],
  settleBankAccountTypeList_Json: [],
  settleBankList_Json: [],
  city_list: [],
  region_list: [],
  aliSubIndustry_list: [],
  subIndustry_list: [],
  license_type_list: [{ id: "1", name: "企业" }, { id: "2", name: "个体工商户" }, { id: "3", name: "事业单位" }, { id: "4", name: "政府" }],
  sList: [],
  total_items: 0,
  page_index: 1,
  page_size: 10,
  mobile: "",
  is_get_code: false
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.PWD_IS_GET_CODE:
      console.log(action.value)
      newState.is_get_code = action.value;
      break;
    case ActionType.API_DATA:
      newState.sList = action.value.data_list;
      newState.page_index = action.value.page_index;
      newState.page_size = action.value.page_size;
      newState.total_items = action.value.total_count;
      break;
    case ActionType.MERCHANT_APP_LIST:
      newState.merchant_app_list = action.value;
      break;
    case ActionType.CERT_JSON:
      newState[ActionType.PROVINCE_JSON] = JSON.parse(action.value[ActionType.PROVINCE_JSON]);
      newState[ActionType.CITY_JSON] = JSON.parse(action.value[ActionType.CITY_JSON]);
      newState[ActionType.REGION_JSON] = JSON.parse(action.value[ActionType.REGION_JSON]);
      newState[ActionType.ALIINDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.ALIINDUSTRYLIST_JSON]);
      newState[ActionType.ALISUBINDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.ALISUBINDUSTRYLIST_JSON]);
      newState[ActionType.INDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.INDUSTRYLIST_JSON]);
      newState[ActionType.SUBINDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.SUBINDUSTRYLIST_JSON]);
      newState[ActionType.SETTLEBANKACCOUNTTYPELIST_JSON] = JSON.parse(action.value[ActionType.SETTLEBANKACCOUNTTYPELIST_JSON]);
      newState[ActionType.SETTLEBANKLIST_JSON] = JSON.parse(action.value[ActionType.SETTLEBANKLIST_JSON]);
      break;
    case ActionType.CITY_LIST:
      newState[ActionType.CITY_LIST] = action.value;
      break;
    case ActionType.REGION_LIST:
      newState[ActionType.REGION_LIST] = action.value;
      break;
    case ActionType.SUBINDUSTRY_LIST:
      newState[ActionType.SUBINDUSTRY_LIST] = action.value;
      break;
    case ActionType.ALISUBINDUSTRY_LIST:
      newState[ActionType.ALISUBINDUSTRY_LIST] = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}