import React, {Component} from "react";
import {connect} from "react-redux";
import Route from './router/routes';
import { Spin } from "antd";

class App extends Component{
  constructor(props) {
    super(props);
  }
  render () {
    return <>
      <Spin delay={100} size={"large"} tip={this.props.load_text} spinning={this.props.spinning}></Spin>
      <Route/>
    </>
  }
}

const stateToProps = (state) => {
  return {
    spinning: state.App.spinning,
    load_text: state.App.load_text
  }
};
const dispatchToProps = (dispatch) => {
  return {

  }
};
export default connect(stateToProps, dispatchToProps)(App);