import * as ActionType from "./actionTypes";

const defaultState = {

};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {

    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}