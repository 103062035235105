import * as ActionType from "./actionTypes";
import Request from "../../utils/api";

const defaultState = {
  login_data: {

  }
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.LOGIN_DATA:
      newState.login_data = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}