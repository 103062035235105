import * as ActionType from "./actionTypes";

const defaultState = {
  spinning: false,
  load_text: "加载中...",
}

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.SPINNING:
      newState[ActionType.SPINNING] = action.value;
      break;
    case ActionType.LOAD_TEXT:
      newState[ActionType.LOAD_TEXT] = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}