
export const CERT_JSON = "cert_json";
export const PROVINCE_JSON = "ProvinceJson";
export const CITY_JSON = "CityJson";
export const REGION_JSON = "RegionJson";
export const ALIINDUSTRYLIST_JSON = "aliIndustryList_Json";
export const ALISUBINDUSTRYLIST_JSON = "aliSubIndustryList_Json";
export const INDUSTRYLIST_JSON = "industryList_Json";
export const SUBINDUSTRYLIST_JSON = "subIndustryList_Json";
export const SETTLEBANKACCOUNTTYPELIST_JSON = "settleBankAccountTypeList_Json";
export const SETTLEBANKLIST_JSON = "settleBankList_Json";
export const CITY_LIST = "city_list";
export const REGION_LIST = "region_list";
export const SUBINDUSTRY_LIST = "subIndustry_list";
export const ALISUBINDUSTRY_LIST = "aliSubIndustry_list";