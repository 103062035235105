import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import asyncComponent from '../utils/asyncComponent';

const Index = asyncComponent(() => import("../components/pc/Index"));
const Login = asyncComponent(() => import("../components/pc/Login"));
const Detail = asyncComponent(() => import("../components/pc/Detail"));
const SolutionDeatil = asyncComponent(() => import("../components/pc/SolutionDeatil"));
const ChannelsinDeatil = asyncComponent(() => import("../components/pc/ChannelsinDeatil"));
const About = asyncComponent(() => import("../components/pc/About"));
const SuccessfulCasesDetail = asyncComponent(() => import("../components/pc/SuccessfulCasesDetail"));
const News = asyncComponent(() => import("../components/pc/News"));
const NewsDetail = asyncComponent(() => import("../components/pc/NewsDetail"));
const WebsiteRegister = asyncComponent(() => import("../components/pc/WebsiteRegister"));
const Merchants = asyncComponent(() => import("../components/pc/Merchants"));
const ForgotPassword = asyncComponent(() => import("../components/pc/ForgotPassword"));
const ProductRegister = asyncComponent(() => import("../components/pc/ProductRegister"));
const ServiceAgreement = asyncComponent(() => import("../components/pc/ServiceAgreement"));
const MobileLogin = asyncComponent(() => import("../components/pc/MobileLogin"));


// react-router4 不再推荐将所有路由规则放在同一个地方集中式路由，子路由应该由父组件动态配置，组件在哪里匹配就在哪里渲染，更加灵活
export default class RouteConfig extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/Index" component={Index} />
          <Route path="/Login" component={Login} />
          <Route path="/WebsiteRegister" component={WebsiteRegister} />
          <Route path="/MobileLogin" component={MobileLogin} />
          <Route path="/Merchants" component={Merchants} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/Detail/:id" component={Detail} />
          <Route path="/SolutionDeatil/:id" component={SolutionDeatil} />
          <Route path="/ChannelsinDeatil/:id" component={ChannelsinDeatil} />
          <Route path="/About" component={About} />
          <Route path="/SuccessfulCasesDetail" component={SuccessfulCasesDetail} />
          <Route path="/News" component={News} />
          <Route path="/NewsDetail" component={NewsDetail} />
          <Route path="/ProductRegister/:id" component={ProductRegister} />
          <Route path="/ProductRegister" component={ServiceAgreement} />

        </Switch>
      </HashRouter>
    )
  }
}

