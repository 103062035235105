import * as ActionType from "./actionTypes";

const defaultState = {
  fixed_top: 0,
  logo_blue: require("../../static/images/logo_blue.png"),
  logo_white: require("../../static/images/logo_white.png"),
  index: 0,
  serial_number: -1,
  applications: [{
    title: "电商",
    list: [{
      serial_number: 1,
      text: "云商小铺",
      desc: "智慧新零售，线上线下销量翻倍",
      url: "/Detail/1",
      icon: require("../../static/images/home/ic_home_yunshangxiaopu@2x.png")
    }, {
      serial_number: 2,
      text: "货叮当",
      desc: "B2B供应链批发商城小程序",
      url: "/Detail/2",
      icon: require("../../static/images/home/ic_home_huodingdang@2x.png")
    }]
  }, {
    title: "AI应用",
    list: [{
      serial_number: 3,
      text: "校园宝",
      desc: "线上缴费，轻松搞定",
      url: "/Detail/3",
      icon: require("../../static/images/home/ic_home_xiaoyuanbao@2x.png")
    }, {
      serial_number: 4,
      text: "易拾堂",
      desc: "智慧食堂综合服务平台",
      url: "/Detail/4",
      icon: require("../../static/images/home/ic_home_yishitang@2x.png")
    }/*,{
      serial_number: 4,
      text: "AI考勤",
      url: "/"
    }*/]
  }, {
    title: "营销推广",
    list: [{
      serial_number: 51,
      text: "微单页",
      desc: "微信营销广告、宣传利器",
      url: "/Detail/51",
      icon: require("../../static/images/home/ic_home_weidanye@2x.png")
    }, {
      serial_number: 52,
      text: "微画册",
      desc: "品牌文化传播、广告营销利器",
      url: "/Detail/52",
      icon: require("../../static/images/home/ic_home_huace@2x.png")
    }, {
      serial_number: 53,
      text: "微名片",
      desc: "赋予电子名片新定义",
      url: "/Detail/53",
      icon: require("../../static/images/home/ic_home_business card@2x.png")
    }, {
      serial_number: 54,
      text: "微资讯",
      desc: "公司资讯、品牌文化宣传好帮手",
      url: "/Detail/54",
      icon: require("../../static/images/home/ic_home_zixun@2x.png")
    }]
  }],
  solutions: [{
    title: "",
    list: [{
      serial_number: 31,
      text: "电商平台",
      url: "/SolutionDeatil/31"
    }, {
      serial_number: 32,
      text: "AI应用",
      url: "/SolutionDeatil/32"
    }, {
      serial_number: 33,
      text: "银行金融",
      url: "/SolutionDeatil/33"
    },{
      serial_number: 34,
      text: "定制开发",
      url: "/SolutionDeatil/34"
    }]
  }],
  // 渠道入驻
  channelsin: [{
    title: "",
    list: [{
      serial_number: 510,
      text: "渠道合作",
      url: "/channelsinDeatil/510"
    }, {
      serial_number: 520,
      text: "入驻崛鼎云",
      url: "/channelsinDeatil/520"
    }]
  }]
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.NAV_FIXED_TOP:
      newState[ActionType.NAV_FIXED_TOP] = action.value;
      break;
    case ActionType.NAV_INDEX:
      newState[ActionType.NAV_INDEX] = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}