import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import Index from './Index/reducer';
import App from './App/reducer';
import Navigation from './Navigation/reducer';
import Detail from './Detail/reducer';
import Login from './Login/reducer';
import WebsiteRegister from './WebsiteRegister/reducer';
import Merchants from './Merchants/reducer';
import ForgotPassword from './ForgotPassword/reducer';
import ProductRegister from './ProductRegister/reducer';
import ServiceAgreement from './ServiceAgreement/reducer';
import mobileLogin from './MobileLogin/reducer';
import thunk from 'redux-thunk';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
const enhancer = composeEnhancer(applyMiddleware(thunk));
const Reducers = { App, Index, Navigation, Detail, Login, WebsiteRegister, Merchants, ForgotPassword, ProductRegister, ServiceAgreement, mobileLogin };

//console.log(Reducers);
const Store = createStore(
        combineReducers(Reducers),
        enhancer  //使用增强函数，组合执行window.__REDUX_DEVTOOLS_EXTENSION__()和applyMiddleware(thunk)
        //没有浏览器调试可直接用 applyMiddleware(thunk)
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()  //浏览器调试查看
);

export default Store;