import axios from 'axios';
import { message } from 'antd';
import storage from './storage';

// 其杰
// export const hostUrl = "http://192.168.5.157:8080/";
//测试域名sit
//export const hostUrl = "https://sitapigateway.v-m.site/MACenter/MerchantAccountCenter/v1.0.0/mch/api";
//export const hostUrl2 = "https://sitapigateway.v-m.site/MACenter/MerchantAccountCenter/mch/api";
// export const hostUrl = "https://sitapigateway.v-m.site/MACenter/MerchantAccountCenter/v1.0.0/mch/api";
//测试域名uat
//export const hostUrl = "http://192.168.6.167:8008/mch/api/";
//正式域名
export const hostUrl = "https://apigateway.klyun.com.cn/MACenter/MerchantAccountCenter/v1.0.0/mch/api";
export const hostUrl2 = "https://apigateway.klyun.com.cn/MACenter/MerchantAccountCenter/mch/api";

//添加一个请求拦截器
axios.interceptors.request.use((config) => {

  config.timeout = 100000;
  config.baseURL = hostUrl;

  config.withCredentials = false;
  config.headers = {
    "Access-Control-Allow-Origin": "*",
    'Content-Type': 'application/json; charset=utf-8',
    'session_token': storage.sessionStorage.get('session_token') ? storage.sessionStorage.get('session_token') : '',
  };
  return config;
}, function (error) {
  // Do something with request error
  console.info("error: ");
  console.info(error);
  return Promise.reject(error);
});
const Request = {
  RequestGet: function (url) {
    return axios.get(url).then(response => {

    }).catch(error => {

    })
  },
  RequestPost: function (url, data, loadText) {
    //message.loading("加载中...", 0);
    return axios.post(url, data)
      .then(function (response) {
        return Promise.resolve(response.data)
      })
      .catch(function (error) {
        return Promise.resolve(error)
      });
  },
  RequestError: function (error) {
    message.destroy();
    if (!error.message.content) {
      error = {
        message: {
          content: '请求失败！'
        }
      }
    }
    message.error(error.message.content, 2);
    if (error.message.content === "Token无效" || error.message.content === "未登录") {
      storage.sessionStorage.clear();
      window.location.hash = '/Login'
    }
  }
};


export default Request;