import * as ActionType from "./actionTypes";

const defaultState = {
  data_list: [{
    id: 1,
    product_name: "云商小铺",
    product_title: "微信小程序+公众号一体化B2C商城解决方案",
    product_desc: "助力企业快速搭建自营平台，精准营销提升效益",
    banner: require("../../static/images/image_banner_b2c.png"),
    detail: [{
      un_image: require("../../static/images/ic_customer_black.png"),
      image: require("../../static/images/ic_customer_white.png"),
      name: "消费者",
      list: [{
        image: require("../../static/images/ic_customer_1.png"),
        text: "无需下载用完即走"
      }, {
        image: require("../../static/images/ic_customer_2.png"),
        text: "打通微信无需登录"
      }, {
        image: require("../../static/images/ic_customer_3.png"),
        text: "不占内存不再卡顿"
      }, {
        image: require("../../static/images/ic_customer_4.png"),
        text: "良好的用户体验"
      }, {
        image: require("../../static/images/ic_customer_5.png"),
        text: "门槛最低路径最短"
      }, {
        image: require("../../static/images/ic_customer_6.png"),
        text: "最适合的线下场景"
      }]
    }, {
      un_image: require("../../static/images/ic_brand_black.png"),
      image: require("../../static/images/ic_brand_white.png"),
      name: "品牌商",
      list: [{
        image: require("../../static/images/ic_brand_1.png"),
        text: "新型营销场景"
      }, {
        image: require("../../static/images/ic_brand_2.png"),
        text: "新型营销通道"
      }, {
        image: require("../../static/images/ic_brand_3.png"),
        text: "新型O2O框架"
      }, {
        image: require("../../static/images/ic_brand_4.png"),
        text: "新型体验方式"
      }, {
        image: require("../../static/images/ic_brand_5.png"),
        text: "新型流量入口"
      }, {
        image: require("../../static/images/ic_brand_6.png"),
        text: "定位为服务者"
      }]
    }
    ]
  }, {
    id: 2,
    product_name: "货叮当",
    product_title: "B2B微信小程序批发商城解决方案",
    product_desc: "助力企业跨入移动社交电商时代",
    banner: require("../../static/images/image_banner_b2b.png"),
    detail: [{
      icon: require("../../static/images/av_b2b_1.png"),
      title: "便捷高效",
      desc: "客户不用跑市场去进货，直接线上进货，节省时间、路费等，且不需要耗费大量的体力和精力，更省时省力。"
    }, {
      icon: require("../../static/images/av_b2b_2.png"),
      title: "营销推广",
      desc: "客户可以随时随地掌握商品的最新资讯，包括新品、特价、秒杀等促销信息，轻松选购新款，价格更优惠。"
    }, {
      icon: require("../../static/images/av_b2b_3.png"),
      title: "个性定制",
      desc: "可根据客户的类别、地区设置不同的商品销售，定制不同的促销方式，业务员也可一对一为客户定制更优惠的报价方案。"
    }, {
      icon: require("../../static/images/av_b2b_4.png"),
      title: "价格优势",
      desc: "B2B批发商城进货直接绕过了中间商或代理商，价格更低更透明，优惠方式多样，让利实实在在。"
    }]
  }, {
    id: 3,
    product_name: "校园宝",
    product_title: "智慧校园综合服务平台",
    product_desc: "推动校园信息化技术快速发展",
    banner: require("../../static/images/image_banner_xiao.png"),
    detail: [{
      icon: require("../../static/images/image_ic1.png"),
      text: "家校互联"
    }, {
      icon: require("../../static/images/image_ic2.png"),
      text: "平安校园"
    }, {
      icon: require("../../static/images/image_ic3.png"),
      text: "智能支付"
    }, {
      icon: require("../../static/images/image_ic4.png"),
      text: "智慧管理"
    }]
  }, {
    id: 4,
    product_name: "易拾堂",
    product_title: "智慧食堂一站式订餐&管理解决方案",
    product_desc: "优化餐饮服务，加强食堂管理",
    banner: require("../../static/images/image_banner_shi.png"),
    list_1: [{
      icon: require("../../static/images/yst_1.png"),
      title: "食堂管理",
      desc: "允许同时添加多个食堂，协同管理，高效便捷"
    }, {
      icon: require("../../static/images/yst_2.png"),
      title: "菜品管理",
      desc: "支持快速导入，创建食堂之间共享的食材/菜品/套餐信息库"
    }, {
      icon: require("../../static/images/yst_3.png"),
      title: "智能排餐",
      desc: "可分食堂每周所属菜单，实现精准采购，精准配餐"
    }, {
      icon: require("../../static/images/yst_4.png"),
      title: "在线订餐",
      desc: "员工可提前在线订餐，食堂按需求配餐，杜绝浪费"
    }, {
      icon: require("../../static/images/yst_5.png"),
      title: "结算统计",
      desc: "经营统计、收/支统计，消费记录可查，资金结算更便捷"
    }, {
      icon: require("../../static/images/yst_6.png"),
      title: "评价管理",
      desc: "食堂/菜品评价，汇总员工反馈信息，提升食堂就餐品质"
    }, {
      icon: require("../../static/images/yst_7.png"),
      title: "线上充值",
      desc: "餐补账户、消费账户独立充值/扣款，可设置消费方式"
    }, {
      icon: require("../../static/images/yst_8.png"),
      title: "互动体验",
      desc: "投票、问卷、活动、健康资讯管理，丰富的互动形式"
    }
    ],
    list_2: [{
      un_select_icon: require("../../static/images/ic_homepage.png"),
      select_icon: require("../../static/images/ic_homepage_green.png"),
      chinese: "首页",
      english: "Home Page",
      phone_img: require("../../static/images/iphone3-1.png")
    }, {
      un_select_icon: require("../../static/images/ic_dininghall.png"),
      select_icon: require("../../static/images/ic_dininghall_green.png"),
      chinese: "食堂",
      english: "Dining Hall",
      phone_img: require("../../static/images/iphone3-2.png")
    }, {
      un_select_icon: require("../../static/images/ic_menu.png"),
      select_icon: require("../../static/images/ic_menu_green.png"),
      chinese: "菜单",
      english: "Menu",
      phone_img: require("../../static/images/iphone3-3.png")
    }, {
      un_select_icon: require("../../static/images/ic_evaluate.png"),
      select_icon: require("../../static/images/ic_evaluate_green.png"),
      chinese: "评价",
      english: "Evaluate",
      phone_img: require("../../static/images/iphone3-4.png")
    }, {
      un_select_icon: require("../../static/images/ic_ordering.png"),
      select_icon: require("../../static/images/ic_ordering_green.png"),
      chinese: "订餐",
      english: "Online Ordering",
      phone_img: require("../../static/images/iphone3-5.png")
    }, {
      un_select_icon: require("../../static/images/ic_pay.png"),
      select_icon: require("../../static/images/ic_pay_green.png"),
      chinese: "扫码取餐",
      english: "Sweep & Take meals",
      phone_img: require("../../static/images/iphone3-6.png")
    }
    ],
    list_3: [{
      image: require("../../static/images/yst_av_1.png"),
      text: "流程管理清晰可查"
    }, {
      image: require("../../static/images/yst_av_2.png"),
      text: "减少人工提升效率"
    }, {
      image: require("../../static/images/yst_av_3.png"),
      text: "成本管控杜绝浪费"
    }]
  }, {
    id: 5,
    product_name: "",
    product_title: "微信公众号营销推广解决方案",
    product_desc: "助力企业推广品牌和产品",
    banner: require("../../static/images/image_banner_yx.jpg"),
    list: [{
      title: "微单页",
      p1: "为中小商户或企业提供活动/事件营销的应用，表现方式丰富，动效更生动有趣，发布活动/事件更快捷、更及时，具有强大的互动性，让用户眼前一亮，为品牌活动传播推广提供高效便捷的互联网新方式。",
      p2: "微单页链接部署于微信自定义菜单，以微信公众号路口传播，或扫描二维码观看",
      p3: "简单、易用，支持免费/付费的报名方式，便于收集汇总用户反馈信息；\n炫酷的特效：背景音乐、拨号、翻页，表现形式生动、多样。",
      imgUrl: require("../../static/images/image_danye.png")
    }, {
      title: "微画册",
      p1: "为中小商户或企业提供形象与文化传播的应用，操作简单，访问便捷，传播能力极强，是品牌形象推广，产品曝光宣传的利器，为品牌展示提供便捷、直观、炫酷的新方式。",
      p2: "画册链接部署于微信自定义菜单，以微信公众号为路口传播",
      p3: "简单、易用，无门槛无需专业美工；\n炫酷的画册特效：背景音乐、拨号、翻页、随时目录导航；\n可通过二维码线上线下同步做推广。",
      imgUrl: require("../../static/images/image_huace.png")
    }, {
      title: "微名片",
      p1: "为中小商户或企业提供电子名片的应用，结合其它呈现的应用赋予电子名片品牌导航的职能，在推广品牌文化和企业信息的同时，为客户提供企业的基本信息和联系方式，提升线上推广的效率，扩宽推广力度。",
      p2: "线下引导 - 实体到店客户，线上推广 - 通过扫码访问品牌公众号或商城小程序",
      p3: "简单、易用，无门槛无需专业美工；\n简洁、大气的模板，自动关联微商、画册等，让名片成为企业导航。",
      imgUrl: require("../../static/images/image_namecard.png")
    }, {
      title: "微资讯",
      p1: "为中小商户或企业提供资讯编辑功能的应用，为品牌文化的传播，企业资讯的公示和传达开辟了便捷的窗口，架起企业与客户之间的沟通桥梁，方便用户更了解企业信息，积极促进品牌文化和企业信息在用户群体之中的推广。",
      p2: "微单页链接部署于微信自定义菜单，以微信公众号路口传播，或扫描二维码观看",
      p3: "拥有微信资讯高级功能：资讯列表页、文章详情评论、点赞等；\n资讯列表内容自由组合、文章详情多形式选择。",
      imgUrl: require("../../static/images/image_news.png")
    }
    ],
  },
  {
    id: 31,
    product_name: "",
    product_title: "崛鼎云电商平台",
    product_desc: "致力于让每一个商家都拥有属于自己的线上商城",
    banner: require("../../static/images/image_banner_shi.png"),
    list_1: [{
      icon: require("../../static/images/solutions/ic_mall@2x.png"),
      title: "开设网上商城",
      desc: "线上线下同步卖货，紧跟主流模式，提升用户体验，增加流量入口"
    }, {
      icon: require("../../static/images/solutions/ic_phone@2x.png"),
      title: "用完留痕",
      desc: "用户使用过的小程序会保留在小程序目录，达到长期推广的效果"
    }, {
      icon: require("../../static/images/solutions/ic_binding@2x.png"),
      title: "关联微信号",
      desc: "可与现有微信公众号绑定，并与粉丝互动，多渠道触达用户"
    }, {
      icon: require("../../static/images/solutions/ic_fission@2x.png"),
      title: "快速分校裂变",
      desc: "顾客一键转发分享商品或店铺链接给好友，轻松获得新顾客促"
    }, {
      icon: require("../../static/images/solutions/ic_plug-in@2x.png"),
      title: "丰富的营销插件",
      desc: "秒杀、满减、满赠、优惠券等丰富的营销方式，快速提升销量"
    }, {
      icon: require("../../static/images/solutions/ic_quick@2x.png"),
      title: "业务管理更便捷",
      desc: "订单状态实时跟踪，及时处理；一键注册，轻松获取客户信息"
    }],
    list_2: [{
      icon: require("../../static/images/solutions/ic_customer@2x.png"),
      title: "实现快速获客",
      desc: "借力微信高达10亿+的用户人数，市场空间巨大"
    }, {
      icon: require("../../static/images/solutions/ic_liuliang@2x.png"),
      title: "一体化营销加速流量裂变",
      desc: "公众号、小程序、微信群互联互通流量变现快速高效"
    }, {
      icon: require("../../static/images/solutions/ic_ascension@2x.png"),
      title: "关联微信号",
      desc: "产品体验比H5更流畅，极大程度的降低转化损耗"
    }],
    list_4: [{
      icon: require("../../static/images/solutions/img_-dianshang_1@2x.png"),
      title: "商家信赖之选"
    }, {
      icon: require("../../static/images/solutions/img_dianshang_2@2x.png"),
      title: "覆盖多个行业和类目"
    }, {
      icon: require("../../static/images/solutions/img_dianshang_3@2x.png"),
      title: "年交易额成倍增长"
    },{
      icon: require("../../static/images/solutions/img_diangshang_4@2x.png"),
      title: "高速的数据处理"
    }]
  },
  {
    id: 32,
    product_name: "",
    product_title: "AI应用解决方案",
    product_desc: "精耕细作，持续创新，精准识别，为用户呈现结构化数据，提升工作效率。",
    banner: require("../../static/images/image_banner_shi.png"),
    list_1: [{
      icon: require("../../static/images/solutions/ic_AI_search@2x.png"),
      title: "图像搜索",
      desc: "以图搜图，在指定图库中搜索出相同或相似的图片"
    }, {
      icon: require("../../static/images/solutions/ic_AI_reading@2x.png"),
      title: "有声阅读",
      desc: "将文字内容转换成流畅自然的语音输出，让应用开口说话"
    }, {
      icon: require("../../static/images/solutions/ic_AI_audit@2x.png"),
      title: "内容审核",
      desc: "为视频、直播、电商、内容社区、O2O行业提供更实时、更低成本、贴合业务需求的审核服务"
    }, {
      icon: require("../../static/images/solutions/ic_AI_entry@2x.png"),
      title: "智能录入",
      desc: "接入语音识别和文字识别技术，丰富信息录入的方式，辅助用户录入信息"
    }, {
      icon: require("../../static/images/solutions/ic_AI_classification@2x.png"),
      title: "文本分类",
      desc: "结合了文章标签和文章分类服务，实现对海量内容的实时的自动分类和打标签"
    }],
    list_2: [{
      icon: require("../../static/images/solutions/ic_AI_picture@2x.png"),
      title: "图片"
    }, {
      icon: require("../../static/images/solutions/ic_AI_voice@2x.png"),
      title: "语音"
    }, {
      icon: require("../../static/images/solutions/ic_AI_video@2x.png"),
      title: "视频"
    }, {
      icon: require("../../static/images/solutions/ic_AI_text@2x.png"),
      title: "文字"
    }]
  },
  {
    id: 33,
    product_name: "",
    product_title: "银行金融产品",
    product_desc: "崛鼎科技以“互联网+”为理念，利用人工智能、云计算、大数据、物联网等于现代制造业结合，为企业、银行及政府提供互联网应用开发、快捷支付、人工智能等综合解决方案。",
    banner: require("../../static/images/image_banner_shi.png")
  },
  {
    id: 34,
    product_name: "",
    product_title: "定制开发",
    product_desc: "以更低的成本更高的效率，满足您的个性化需求",
    banner: require("../../static/images/image_banner_shi.png"),
    list_1: [{
      icon: require("../../static/images/yst_1.png"),
      title: "开设网上商城",
      desc: ""
    }, {
      icon: require("../../static/images/yst_2.png"),
      title: "用完留痕",
      desc: ""
    }, {
      icon: require("../../static/images/yst_3.png"),
      title: "关联微信号",
      desc: ""
    }, {
      icon: require("../../static/images/yst_4.png"),
      title: "快速分校裂变",
      desc: ""
    }]
  },{
    id: 510,
    product_name: "",
    product_title: "崛鼎云代理商全国火热招商中",
    product_desc: "与代理商伙伴建立共赢发展模式 构建本地化移动互联平台",
    banner: require("../../static/images/image_banner_shi.png"),
    list_1: [{
      icon: require("../../static/images/channelsin/img_qudao3@2x.png")
    }, {
      icon: require("../../static/images/channelsin/img_qudao4@2x.png")
    }, {
      icon: require("../../static/images/channelsin/img_qudao5@2x.png")
    },{
      icon: require("../../static/images/channelsin/img_qudao6@2x.png")
    }, {
      icon: require("../../static/images/channelsin/img_qudao7@2x.png")
    }, {
      icon: require("../../static/images/channelsin/img_qudao8@2x.png")
    },{
      icon: require("../../static/images/channelsin/img_qudao9@2x.png")
    }, {
      icon: require("../../static/images/channelsin/img_qudao10@2x.png")
    }, {
      icon: require("../../static/images/channelsin/img_qudao11@2x.png")
    },{
      icon: require("../../static/images/channelsin/img_qudao12@2x.png")
    }],
    list_2: [{
      icon: require("../../static/images/channelsin/img_qudao3@2x.png"),
      title: "高返点",
      desc: "总部提供高额返点优惠辅助代理商更好的发展公司，储备人才，拓展当地市场。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao4@2x.png"),
      title: "产品支持",
      desc: "总部为代理商提供最完善的全行业营销解决方案，根据市场需求产品迭代创新速度快，通过一次次快速迭代，使产品赢得用户的广泛青睐，帮助代理商牢牢抓住客户资源。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao5@2x.png"),
      title: "培训支持",
      desc: "总部会定期针对代理商人员的需求进行产品、销售、售后、管理等相关培训，同时渠道经理会定期到代理商公司给予落地扶持，快速提高代理商团队的战斗力，帮助代理商建立完善的企业文化，打造核心竞争力。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao6@2x.png"),
      title: "资源支持",
      desc: "总部通过网络推广得到的资源将全部转交给当地代理商，代理商无需承担推广费用。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao7@2x.png"),
      title: "推广支持",
      desc: "总部会根据不同的市场、产品特性不定期的在全国或者当地进行市场推广活动扩大知名度与影响力。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao8@2x.png"),
      title: "物料支持",
      desc: "总部定期或者不定期的会根据服务商的情况给予物料支持，利于服务商在当地开展业务。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao11@2x.png"),
      title: "技术支持",
      desc: "崛鼎云拥有强大的研发团队，保障系统的安全性、稳定性、可靠性。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao10@2x.png"),
      title: "售后客服",
      desc: "崛鼎云全方位协助服务商打造售后团队，解决重大紧急问题和活动报备支持，并提供客户运营支持。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao12@2x.png"),
      title: "区域保护",
      desc: "崛鼎云提供7×12小时在线技术服务，保障问题高效、快速的解决，让服务商、客户没有后顾之忧。"
    }, {
      icon: require("../../static/images/channelsin/img_qudao9@2x.png"),
      title: "技术保障",
      desc: "总部对于有代理区域的客户不直接参与销售，微盟总部将推广获得的客户资源按规则分配给当地服务商进行转化，同时总部不允许服务商跨区域销售以保证市场的规范性，确保服务商的利益最大化。"
    }],
    list_3: [{
      icon: require("../../static/images/channelsin/ic_company@2x.png"),
      title: "具备公司资质",
      desc: "有合法营业执照和独立法人代表，有良好商业信誉和口碑"
    }, {
      icon: require("../../static/images/channelsin/ic_qizhuyun@2x.png"),
      title: "认可崛鼎云文化",
      desc: "认同崛鼎云产品价值和文化概念，愿意跟崛鼎云长期合作"
    }, {
      icon: require("../../static/images/channelsin/ic_Internet@2x.png"),
      title: "具有行业背景",
      desc: "有互联网背景，对社交电商、SaaS行业有了解"
    }, {
      icon: require("../../static/images/channelsin/ic_team@2x.png"),
      title: "具有销售团队",
      desc: "有专属且成熟的销售团队，负责人有团队管理经验"
    }, {
      icon: require("../../static/images/channelsin/ic_resources@2x.png"),
      title: "具有客户资源",
      desc: "拥有当地丰富的企业级资源或线下门店资源"
    }]
  },{
    id: 520,
    product_name: "",
    product_title: "入驻崛鼎云  开启成功之路",
    product_desc: "帮你增流量、增销量、增粉丝，实现业绩的野蛮生长",
    banner: require("../../static/images/image_banner_shi.png"),
    list_1: [{
      icon: require("../../static/images/channelsin/ic_ruzhu_liuliang@2x.png"),
      title: "全渠道卖货，帮你增流量",
      desc: "借力微信庞大的用户群体，开启线上线下流量互通"
    }, {
      icon: require("../../static/images/channelsin/ic_ruzhu_increase@2x.png"),
      title: "人人分销卖货，帮你增销量",
      desc: "分佣推广，顾客、粉丝帮你卖货，裂变分销快速拓展销售渠道，促进业绩增长"
    }, {
      icon: require("../../static/images/channelsin/ic_ruzhu_fans@2x.png"),
      title: "多个维度，帮你增粉丝",
      desc: "公众号、小程序等，一键开店，统一管理，全方位引流"
    }],
    list_2: [{
      icon: require("../../static/images/channelsin/ic_ruzhu_brand@2x.png"),
      title: "品牌形象商城"
    }, {
      icon: require("../../static/images/channelsin/ic_ruzhu_system@2x.png"),
      title: "私域经营系统"
    }, {
      icon: require("../../static/images/channelsin/ic_ruzhu_link@2x.png"),
      title: "数据互联互通"
    },{
      icon: require("../../static/images/channelsin/ic_ruzhu_marketing@2x.png"),
      title: "大数据营销"
    }, {
      icon: require("../../static/images/channelsin/ic_ruzhu_team@2x.png"),
      title: "专业服务团队"
    }]
  }],
  current_id: 0
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.CURRENT_ID:
      newState.current_id = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}