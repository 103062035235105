import * as ActionType from "./actionTypes";

const defaultState = {
  mobile: "",
  is_get_code: false
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.PWD_MOBILE:
      newState.mobile = action.value;
      break;
    case ActionType.PWD_IS_GET_CODE:
      console.log(action.value)
      newState.is_get_code = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}