import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store/store';
import App from './App';
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './static/style/index.css';
import "./static/style/main-pc.css";
import "antd/dist/antd.css";
import * as serviceWorker from './serviceWorker';


moment.locale('zh-cn');
ReactDOM.render(<Provider store={store}><ConfigProvider
  locale={zh_CN}>
    <App />
  </ConfigProvider></Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();