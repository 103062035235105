import * as ActionType from "./actionTypes";

const defaultState = {
  login_data: {},
  is_get_code: false
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.REG_IS_GET_CODE:
      newState.is_get_code = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}