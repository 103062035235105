import * as ActionType from "./actionTypes";

const defaultState = {
  ProvinceJson: [],
  CityJson: [],
  RegionJson: [],
  aliIndustryList_Json: [],
  aliSubIndustryList_Json: [],
  industryList_Json: [],
  subIndustryList_Json: [],
  settleBankAccountTypeList_Json: [],
  settleBankList_Json: [],
  city_list: [],
  region_list: [],
  aliSubIndustry_list: [],
  subIndustry_list: [],
  license_type_list: [{id: "1", name: "企业"},{id: "2", name: "个体工商户"},{id: "3", name: "事业单位"},{id: "4", name: "政府"}],
};

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ActionType.CERT_JSON:
      newState[ActionType.PROVINCE_JSON] = JSON.parse(action.value[ActionType.PROVINCE_JSON]);
      newState[ActionType.CITY_JSON] = JSON.parse(action.value[ActionType.CITY_JSON]);
      newState[ActionType.REGION_JSON] = JSON.parse(action.value[ActionType.REGION_JSON]);
      newState[ActionType.ALIINDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.ALIINDUSTRYLIST_JSON]);
      newState[ActionType.ALISUBINDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.ALISUBINDUSTRYLIST_JSON]);
      newState[ActionType.INDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.INDUSTRYLIST_JSON]);
      newState[ActionType.SUBINDUSTRYLIST_JSON] = JSON.parse(action.value[ActionType.SUBINDUSTRYLIST_JSON]);
      newState[ActionType.SETTLEBANKACCOUNTTYPELIST_JSON] = JSON.parse(action.value[ActionType.SETTLEBANKACCOUNTTYPELIST_JSON]);
      newState[ActionType.SETTLEBANKLIST_JSON] = JSON.parse(action.value[ActionType.SETTLEBANKLIST_JSON]);
      break;
    case ActionType.CITY_LIST:
      newState[ActionType.CITY_LIST] = action.value;
      break;
    case ActionType.REGION_LIST:
      newState[ActionType.REGION_LIST] = action.value;
      break;
    case ActionType.SUBINDUSTRY_LIST:
      newState[ActionType.SUBINDUSTRY_LIST] = action.value;
      break;
    case ActionType.ALISUBINDUSTRY_LIST:
      newState[ActionType.ALISUBINDUSTRY_LIST] = action.value;
      break;
    default:
      //console.log("未找到常量" + action.type);
      break;
  }
  return newState
}